<template src="./data.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce';
import store from '../../../Store';
import CustomerStore from '../CustomerStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "Data",
  components: {'tinymceEditor': Editor, DatePicker},
  mixins: [tinymce, RegisterStoreModule],
  data: function () {
    return {
      customer: {
        id: null,
        salutation: 1,
        address: {},
        payment: {
          method: {},
          dates: {}
        },
        educations: [],
        branch: {},
        teacher: {},
        referral: {},
        marketing: {
          channel: null,
          event: null
        }
      },
      payment_installments: false,
      payment_methods: [],
      teachers: [],
      branches: [],
      camps: {
        fc: [],
        mc: []
      },
      jmpl_event: {measure: {}},
      salutations: [
        { value: 1, text: 'Du' },
        { value: 2, text: 'Sie' },
      ],
      search_text: '',
      search_cache: '',
      timeout: false,
      search_result: []
    }
  },
  beforeRouteEnter: function (to, from, next) {
    Promise.all([
      store.dispatch('fetchBranches'),
      store.dispatch('fetchTeachers')
    ]).finally(() => {
      next((vm) => {})
    })
  },
  computed: {
    ...mapFields('customers', [
      'channels',
      'events',
    ]),
  },
  created: function(){
    let self = this;
    self.registerStoreModule('customers', CustomerStore);
    self.$store.dispatch('customers/fetchChannels');
    self.$store.dispatch('customers/fetchEvents');

    if(self.$route.params.id > 0) {
      Http.request('GET', '/customers/' + self.$route.params.id).then((customer) => {
        self.customer = customer.data;
        self.$store.commit('set', ['breadcrumbTxt', self.customer.name]);

        if(self.customer.salutation === null){
          self.customer.salutation = 1;
        }
        if(self.customer.branch === null){
          self.customer.branch = {};
        }
        if(self.customer.teacher === null){
          self.customer.teacher = {};
        }
        if(self.customer.payment.method === null){
          self.customer.payment.method = {};
        }
        if(self.customer.payment.dates.length > 0){
          self.payment_installments = true;
        }
      });
    }

    if(self.$route.params.referral > 0) {
      Http.request('GET', '/customers/' + self.$route.params.referral).then((customer) => {
        self.customer.referral.id = customer.data.id;
        self.customer.referral.name = customer.data.first_name + ' ' + customer.data.last_name;
      });
    }

    Http.request('GET', '/customers/camps/' + (self.$route.params.id > 0 ? self.$route.params.id : '')).then((camps) => {
      camps.data.forEach(function (camp) {
        self.camps[camp.type].push({
          value: camp,
          text: self.$options.filters.formatDate(camp.start) + ' - ' + self.$options.filters.formatDate(camp.end)
        });
      });
    });

    Http.request('GET', '/customers/payment_methods').then((payment_methods) => {
      self.payment_methods = payment_methods.data;
    });

    self.branches = self.$store.getters.get('branches');
    self.teachers = self.$store.getters.get('teachers');
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    async save () {
      let self = this;
      let isValid = await self.$refs.dataObserver.validate();
      if (!isValid) {
        self.$bvToast.toast('Bitte korrigiere die markierten Felder', {
          title: 'Pflichtfelder',
          variant: 'warning',
          autoHideDelay: 5000
        });
        return false;
      }

      if(self.customer.birthday === '0000-00-00'){
        delete self.customer.birthday;
      }
      if(!self.payment_installments){
        delete self.customer.payment.dates;
      }

      Http.request('POST', '/customers/' + (self.$route.params.id > 0 ? self.$route.params.id : ''), self.customer).then(function (response) {
        self.$router.push('/customers/dashboard/' + self.$route.params.type + '/details/' + response.data.id + '/' + self.$route.params.education + '/profile');
      });
    },
    searchKm (value) {
      let self = this;
      if(value.length > 1 && !self.timeout) {
        self.timeout = true;
        Http.request('GET', '/handbook?jmpl=1&search=' + value, null, false).then(function (response) {
          self.search_result = response.data;
          setTimeout(function (){
            self.timeout = false;
            if(self.search_cache !== value){
              self.searchKm(self.search_cache);
            }
          }, 500)
        });
      }
      else if (self.timeout){
        self.search_cache = value;
      }
    },
    selectKm (value) {
      let self = this;
      self.search_text = '';
      let km = self.search_result.filter(item => item.name === value);
      if(km.length > 0){
        self.jmpl_event.measure = km[0];
      }
    },
    saveEvent () {
      let self = this;

      if(!self.jmpl_event.measure.id){
        self.$bvToast.toast('Bitte wählen sie eine Massnahme aus der Liste aus (Suche durch eingabe)', {
          title: 'Pflichtfelder',
          variant: 'danger',
          autoHideDelay: 5000
        });
        return;
      }

      let post = {
        start: self.jmpl_event.date.start,
        end: self.jmpl_event.date.end,
        measure: self.jmpl_event.measure
      };
      Http.request('POST', '/customers/marketing/events', post).then(function (response) {
        self.$store.dispatch('customers/fetchEvents');
        self.customer.marketing.event = response.data;
        self.$bvModal.hide('new_jmpl_event');
        Object.assign(self.$data.jmpl_event, self.$options.data().jmpl_event);
      });
    },
    deleteEvent () {
      let self = this;
      self.$bvModal.msgBoxConfirm('Möchtest du dieses Event aus dem Werbeplan unwiderruflich löschen?', {
        headerBgVariant: 'primary',
        title: 'Löschen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if (value === true && self.customer.marketing.event && self.customer.marketing.event.id > 0) {
          Http.request('DELETE', '/customers/marketing/events/' + self.customer.marketing.event.id).then(function (response) {
            self.$store.dispatch('customers/fetchEvents');
            self.customer.marketing.event = null;
          });
        }
      });
    },
  }
}
</script>

<style scoped></style>
